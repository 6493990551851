import React from 'react';
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import LoginPage from './login.js'
import MainPage from './MainPage.js'


const Service = () => {
  return (
    <div>
      ServicePage
    </div>
  )
}

const RoutesMain = () => {
  const loggedIn = useSelector(state => state.user.loggedIn)
  console.log("LoggedIn", loggedIn)

  return (
    <div>
      {/* <h2 style={{color: 'pink'}}>PORTAL</h2> */}
     <Routes>
      <Route path="/" element={<LoginPage />} />
      {/* <Route path="mainpage/*" element={<MainPage />} /> */}
      {/* <Route path="embedded" element={<EmbeddedApp />} /> */}
      {/* <Route path="mainpage/*" element={<MainPage />} /> */}
      <Route element={<ProtectedRoute user={loggedIn} />}>
        <Route path="mainpage/*" element={<MainPage />} />
        {/* <Route path="nexsci/*" /> */}
      </Route>
    </Routes>
    </div>
  );
}

const ProtectedRoute = ({
  user,
  redirectPath = '/',
}) => {
  if (user === false) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default RoutesMain;

function EmbeddedApp() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src="https://ecologportal.duckdns.org/nexsci/"
        //src="localhost:5173"
        style={{
          border: 'none',
          width: '100%',
          height: '100%',
        }}
        title="Embedded App"
      ></iframe>
    </div>
  );
}

function EmbeddedDocsApp() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src="https://ecologportal.duckdns.org/docs/"
        style={{
          border: 'none',
          width: '100%',
          height: '100%',
        }}
        title="Embedded App"
      ></iframe>
    </div>
  );
}
